*{
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-display: swap;
}

body{
  will-change: auto;
  background: black;
}

.page-enter {
  opacity: 0;
  transform: translateX(100%); /* Вводим с правой стороны */
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms ease;
}
.page-exit {
  opacity: 1;
  transform: translateX(0);
}
.page-exit-active {
  opacity: 0;
  transform: translateX(-100%); /* Уходим в левую сторону */
  transition: opacity 200ms, transform 200ms ease;
}
